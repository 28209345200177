import React, {useEffect} from 'react';
import {graphql, navigate} from "gatsby";

const CloudArticleView = ({data}) => {
  const {categories} = data;
    useEffect(() => {
      if(categories.url === 'aws'){
        navigate('/aws/getting-started')
      } else if(categories.url === 'ibm'){
        navigate('/ibm/creating-your-account')
      } else {
        navigate('/gcp/getting-started')
      }
    }, []);
    return null


}
export default CloudArticleView;
export const query = graphql`
    query CloudCaterogies($url: String) {
        categories(url: {eq: $url}) {
            name
            url
        }
    }
`;
